import * as React from 'react'
import { Col, Row, Container, Form, Button, Alert } from 'react-bootstrap'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import HeroUnit from '../components/HeroUnit'
import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { getCookieConsentValue } from 'react-cookie-consent'
import { CONSENT_COOKIE } from '../components/GDPRCookieConsent'
import Cookies from 'js-cookie'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import { useLocation } from '@reach/router'
import SEOTools from '../components/SEOTools'
import { getSrc } from 'gatsby-plugin-image'

/**
 * @returns {JSX.Element}
 * @constructor
 */
const CookieSettingsPage = ({ data }) => {
    const location = useLocation()
    const [acceptTracking, setAcceptTracking] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    useEffect(() => {
        setAcceptTracking(getCookieConsentValue(CONSENT_COOKIE) === 'true')
    }, [])

    const handleSubmit = e => {
        e.preventDefault()

        const extraCookieOptions = {}

        // If a cookie domain has been set in the .env variables (and we are in production context), use that. For local
        // development (and other environments) we want to use host only cookie, to prevent banner from always popping up.
        // But for the live site, we want to set our domain, to allow the cookie to be read on all subdomains.
        if (process.env.CONTEXT === 'production' && process.env.GATSBY_GDPR_COOKIE_DOMAIN) {
            extraCookieOptions.domain = process.env.GATSBY_GDPR_COOKIE_DOMAIN
        }

        Cookies.set(CONSENT_COOKIE, acceptTracking.toString(), { expires: 365, ...extraCookieOptions })

        if (acceptTracking) {
            initializeAndTrack(location)
        } else {
            setTimeout(() => (window.location.href = '/'), 3000)
        }

        setSubmitted(true)
    }

    return (
        <PageStyles>
            <SEOTools
                title='Cookie settings'
                description='Check your cookie settings for our site.'
                image={getSrc(data.heroBg)}
            />
            <Layout>
                <HeroUnit backgroundImage={data.heroBg} className='darker-background-overlay text-white'>
                    <Row className='d-flex align-items-center justify-content-center text-center my-5'>
                        <Col md='8'>
                            <h1>Cookie settings</h1>
                        </Col>
                    </Row>
                </HeroUnit>
                <section className='mt-5 mb-5 pt-5 pb-4'>
                    <Container>
                        <Row className='justify-content-center mb-4'>
                            <Col lg='6'>
                                {submitted && (
                                    <Alert
                                        variant='success'
                                        className='mb-4 px-4'
                                        onClose={() => setSubmitted(false)}
                                        dismissible>
                                        Your settings have been saved.
                                    </Alert>
                                )}
                                <h2>You control your data</h2>
                                <p className='mb-5'>
                                    Our website uses first and third party cookies that help the website function, so
                                    that we can provide you with the best possible user experience. We will only use
                                    analytical cookies if you consent to it. Otherwise if you deny the use of analytical
                                    cookies, we may still use necessary first party cookies if you continue browsing our
                                    website. Our Cookie policy is found <Link to='/cookie-policy'>here</Link>.
                                </p>
                                <hr />
                            </Col>
                        </Row>
                        <Form onSubmit={handleSubmit}>
                            <Row className='justify-content-center mb-5'>
                                <Col lg='4' xs='8'>
                                    <label htmlFor='accept-switch'>I accept the usage of analytical cookies</label>
                                </Col>
                                <Col lg='2' xs='4' className='text-end'>
                                    <Form.Check type='switch' id='accept-switch'>
                                        <Form.Check.Input
                                            checked={acceptTracking}
                                            onChange={e => setAcceptTracking(e.target.checked)}
                                            className='px-4'
                                        />
                                    </Form.Check>
                                </Col>
                            </Row>

                            <Row className='justify-content-center'>
                                <Col lg='6' className='text-center'>
                                    <Button variant='success' type='submit' className='px-5'>
                                        Save preferences
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </section>
            </Layout>
        </PageStyles>
    )
}

export const query = graphql`
    query {
        heroBg: file(relativePath: { eq: "cookies_bg.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 3840
                    height: 500
                    placeholder: BLURRED
                    quality: 80
                    transformOptions: { cropFocus: CENTER, fit: OUTSIDE }
                )
            }
        }
    }
`

const PageStyles = styled.div`
    form {
        .form-check-input:checked {
            background-color: var(--bs-success);
        }
    }
`

export default CookieSettingsPage
